import getTerms from './getTerms';
import postTerm from './postTerm';
import putTerm from './putTerm';
import deleteTerms from './deleteTerms';
export default {
  getTerms,
  postTerm,
  putTerm,
  deleteTerms,
};
