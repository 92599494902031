import { bindActionCreators } from 'redux';
import { compose } from 'recompose';
import endpoints from 'api/endpoints';
import { requestAsync, querySelectors } from '@digitalwing.co/redux-query-immutable';
import { connect } from 'react-redux';
import { terms } from 'api';

import { getTerms } from './selectors';

const TermsApiHOC = () => (WrappedComponent) =>
  compose(
    connect(
      (state) => ({
        terms: getTerms(state),

        termsIsFetching:
          querySelectors.isPending(state.get('queries'), { queryKey: endpoints.getTerms() }) ||
          false,
      }),
      (dispatch) => ({
        ...bindActionCreators(
          {
            getTerms: () => requestAsync(terms.getTerms()),
            postTerms: (args) => requestAsync(terms.postTerm(args)),
            putTerm: (args) => requestAsync(terms.putTerm(args)),
            deleteTerms: (args) => requestAsync(terms.deleteTerms(args)),
          },
          dispatch,
        ),
      }),
    ),
  )(WrappedComponent);

export default TermsApiHOC;
