import { getParams } from 'helpers';

const usersHost = process.env.REACT_APP_SERVICE_USERS;
const paymentHost = process.env.REACT_APP_SERVICE_PAYMENT;

const hostForum = process.env.REACT_APP_SERVICE_FORUM;
const hostBuckets = process.env.REACT_APP_SERVICE_BUCKETS;

const hostTranslations = process.env.REACT_APP_SERVICE_TRANSLATIONS;

export default {
  // auth
  getLoginUrl: () => `${usersHost}/auth`,
  getUserUrl: () => `${usersHost}/user`,
  getLogOutUrl: () => `${usersHost}/logout`,

  // resource
  getCryptocurrenciesUrl: () => `${paymentHost}/crypto`,
  getBanksUrl: () => `${paymentHost}/bank`,
  getPaymentSystemsUrl: () => `${paymentHost}/ps`,

  // stories
  getStoryUrl: () => `${hostForum}/news/stories`,
  getDeleteStoryUrl: (id) => `${hostForum}/news/stories/${id}`,

  // news
  getNewsUrl: (body = {}) => `${hostForum}/news${getParams(body)}`,
  getSingleNewsUrl: (id, locale) => `${hostForum}/news/${id}${locale ? `/locales/${locale}` : ''}`,

  // users
  getUsersUrl: (body = {}) => `${usersHost}/user/find${getParams(body)}`,
  getBlockUserUrl: (status) => `${usersHost}/user/forum/${status}`,

  // forum boards
  getBoardsUrl: () => `${hostForum}/boards`,
  getSingleBoardUrl: (id, body = {}) => `${hostForum}/boards/${id}${getParams(body)}`,

  // forum topics
  getSingleTopicUrl: (id, body = {}) => `${hostForum}/topics/${id}${getParams(body)}`,
  getTopicsUrl: () => `${hostForum}/topics`,

  // forum posts
  getPostsUrl: () => `${hostForum}/messages`,
  getSinglePostUrl: (id) => `${hostForum}/messages/${id}`,

  // faq
  getFaqArticles: () => `${hostForum}/faq/articles`,
  getFaqTopicsUrl: () => `${hostForum}/faq/topics`,
  getFaqTopicByIdUrl: (id) => `${hostForum}/faq/topics/${id}`,
  getFaqArticleByIdUrl: (id) => `${hostForum}/faq/articles/${id}`,
  getFaqArticleUrl: (id, locale) => `${hostForum}/faq/articles/${id}/locales/${locale}`,

  // buckets
  getFileBucketUrl: (bucketName) => `${hostBuckets}/file/${bucketName}`,
  getUploadingBucketUrl: (bucketName) => `${hostBuckets}/api/v1/uploading/${bucketName}`,

  // translations
  getTranslationsUrl: () => `${hostTranslations}/locales`,
  getPutTranslationsUrl: () => `${hostTranslations}/locales/update`,

  // terms
  getTerms: () => `${usersHost}/terms/all`,
  getTermsUrl: () => `${usersHost}/terms`,
  putTermsUrl: (version, lang) => `${usersHost}/terms/${version}/locales/${lang}`,
  deleteTermsUrl: (version, lang) => `${usersHost}/terms/${version}/locales/${lang}`,
};
