import endpoints from 'api/endpoints';
import Immutable from 'immutable';

export default ({ body }) => ({
  url: endpoints.getTermsUrl(),
  queryKey: `post${endpoints.getTermsUrl()}`,
  transform: response => response.data,
  transformResult: response => ({
    terms: response.data,
  }),
  body,
  force: true,
  meta: {
    authToken: true,
  },
  options: {
    headers: {
      Accept: 'application/json',
    },
    method: 'POST',
  },
  update: {
    term: (prevEntities = Immutable.Map(), nextEntities) =>
      prevEntities.mergeDeep(nextEntities),
  },
  updateResult: {
    terms: (nextEntities = Immutable.List(), nextResult) => nextEntities.push(nextResult),
  },
});
